import { useMutation, UseMutationResult } from 'react-query';

import {
  Contractor,
  RequestArchivedContractorSuccess,
  RequestManagerToReassignSuccess,
  RequestResumeContractorSuccess,
  RequestSuspendContractorSuccess,
  ResponseSuccess,
} from 'api';
import { useFetchContractorDirectory } from 'hooks';
type RestoreAssociateRequest = {
  data: {
    firstName: Contractor['id'];
    lastName: Contractor['lastName'];
    email: Contractor['email'];
    hireDate: Contractor['hireDate'];
    recruiter: string | null;
    internalRoles: Contractor['internalRoles'] | null;
    archived: Contractor['archived'];
    typeOfAssociation: Contractor['typeOfAssociation'];
    personalEmail: Contractor['personalEmail'];
    phoneNumber: Contractor['phoneNumber'];
  };
  contractorId: string;
};
interface ProfileMutation {
  submitEditContractorRequest: UseMutationResult<
    ResponseSuccess,
    Error,
    RestoreAssociateRequest | RequestArchivedContractorSuccess
  >;
  submitEditContractorRequestById: UseMutationResult<
    ResponseSuccess,
    Error,
    RequestManagerToReassignSuccess
  >;
  submitSuspendContractorById: UseMutationResult<
    ResponseSuccess,
    Error,
    RequestSuspendContractorSuccess
  >;
  submitResumeContractorById: UseMutationResult<
    ResponseSuccess,
    Error,
    RequestSuspendContractorSuccess
  >;
}

function useProfileMutation(): ProfileMutation {
  const authorisedFetch = useFetchContractorDirectory();

  const submitEditContractorRequest = useMutation<
    ResponseSuccess,
    Error,
    RestoreAssociateRequest | RequestArchivedContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitEditContractorRequestById = useMutation<
    ResponseSuccess,
    Error,
    RequestManagerToReassignSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitSuspendContractorById = useMutation<
    ResponseSuccess,
    Error,
    RequestSuspendContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  const submitResumeContractorById = useMutation<
    ResponseSuccess,
    Error,
    RequestResumeContractorSuccess
  >(async ({ data, contractorId }) => {
    const response = await authorisedFetch(`/contractors/id/${contractorId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });

    return await response.json();
  });

  //  TODO: refactor, functions THE SAME

  return {
    submitEditContractorRequest,
    submitEditContractorRequestById,
    submitSuspendContractorById,
    submitResumeContractorById,
  };
}

export default useProfileMutation;
