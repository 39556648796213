import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Contractor } from 'api';
import { notify } from 'components/notification';
import { useModalHandlers, useProfileMutation } from 'hooks';

function useSuspendModalHandlers() {
  const queryClient = useQueryClient();

  const [suspendContractorId, setSuspendContractorId] = useState<Contractor['id']>('');
  const {
    isOpenModal: isSuspendModalOpen,
    closeModal: closeSuspendModal,
    openModal: openSuspendModal,
  } = useModalHandlers();

  const handleSuspendClick = (id: Contractor['id']) => {
    setSuspendContractorId(id);
    openSuspendModal();
  };

  const { submitSuspendContractorById } = useProfileMutation();

  const handleSubmitSuspend = () => {
    submitSuspendContractorById.mutate(
      {
        data: {
          suspended: true,
        },
        contractorId: suspendContractorId,
      },
      {
        onSuccess: ({ message }) => {
          queryClient.invalidateQueries('contractors');
          queryClient.invalidateQueries('erpContractors');

          queryClient.invalidateQueries(['profile', suspendContractorId]);
          queryClient.invalidateQueries(['erpProfile', suspendContractorId]);

          notify({ content: message, type: 'success' });
          closeSuspendModal();
        },
        onError: (error) => {
          notify({
            content: JSON.parse(error.message).message,
            type: 'error',
          });
        },
      },
    );
  };

  return {
    isSuspendModalOpen,
    isSuspendSubmitLoading: submitSuspendContractorById.isLoading,
    closeSuspendModal,
    handleSuspendClick,
    handleSubmitSuspend,
  };
}

export default useSuspendModalHandlers;
