import { useCallback, useState } from 'react';

interface ModalHandlers {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
}

function useModalHandlers(): ModalHandlers {
  const [isOpenModal, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const toggleModal = useCallback(() => setIsOpen((prev) => !prev), []);

  return {
    isOpenModal,
    openModal,
    closeModal,
    toggleModal,
  };
}

export default useModalHandlers;
