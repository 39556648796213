import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ResponseDepartmentsSuccess } from 'api';
import { useFetchErp } from 'hooks';

function useDepartmentsQuery(
  options?: UseQueryOptions<ResponseDepartmentsSuccess, Error>,
): UseQueryResult<ResponseDepartmentsSuccess, Error> {
  const authorisedFetch = useFetchErp();

  return useQuery<ResponseDepartmentsSuccess, Error>(
    'departments',
    async () => {
      const response = await authorisedFetch('/public/departments', {
        method: 'GET',
      });

      return await response.json();
    },
    options,
  );
}

export default useDepartmentsQuery;
