import { colors } from '@atlaskit/theme';
import { ReactElement } from 'react';
import styled from 'styled-components/macro';

import useTopNavigationContentHandlers from './useTopNavigationContentHandlers';
import { HeaderAvatarDropdown } from '../headerAvatarDropdown';
import { InfoCurrentUser } from '../infoCurrentUser';
import { Notifications } from '../notification';
import { TopNavigationLogo } from '../topNavigationLogo';

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 1rem;
  background-color: ${colors.N0};
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 20%);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function TopNavigationContent(): ReactElement {
  const {
    handleSignOut,
    navigateToProfile,
    avatarSrc,
    userFullName,
    tooltipContent,
    isMultipleRoles,
    toggleModal,
    closeModal,
    isOpenModal,
    notifications,
    handleClickOnRiskRadarButton,
    handleClickNineBoxInformed,
  } = useTopNavigationContentHandlers();

  return (
    <MainWrapper>
      <TopNavigationLogo />
      <Wrapper>
        <Notifications
          isOpenPopup={isOpenModal}
          notifications={notifications}
          togglePopup={toggleModal}
          closePopup={closeModal}
          handleClickOnRiskRadarButton={handleClickOnRiskRadarButton}
          handleClickNineBoxInformed={handleClickNineBoxInformed}
        />
        <HeaderAvatarDropdown
          handleSignOut={handleSignOut}
          navigateToProfile={navigateToProfile}
          avatarSrc={avatarSrc}
          userFullName={userFullName}
        />
        <InfoCurrentUser
          userFullName={userFullName}
          tooltipContent={tooltipContent}
          isMultipleRoles={isMultipleRoles}
        />
      </Wrapper>
    </MainWrapper>
  );
}

export default TopNavigationContent;
